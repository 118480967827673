import { getAccessToken } from "../slices/session";
import axios from "./axios";

export async function fetchCompany(params) {
  return axios
    .get(`/company`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateCompany(payload, params) {
  return axios.put(`/company/`, { company: payload, params });
}

export async function fetchTripletexEvents() {
  return axios.get("/company/settings/integrations/tripletex/events");
}

export async function fetchPaymentTypes() {
  return axios.get("/company/settings/payment_types");
}

export async function subscribeTripletexEvent(event) {
  return axios.put("/company/settings/integrations/tripletex/subscribe", {
    event,
  });
}
export async function unsubscribeTripletexEvent(event) {
  return axios.put("/company/settings/integrations/tripletex/unsubscribe", {
    event,
  });
}

export async function syncModule(params) {
  return axios.post(`/tripletex/sync`, { sync: params });
}

export async function deleteTripletexSubscriptions() {
  return axios.put(
    `/company/settings/integrations/tripletex/delete_subscriptions`
  );
}

export async function syncEventSource(params) {
  const authToken = getAccessToken();
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const queryParams = new URLSearchParams(params).toString();
  const url = `${baseURL}/tripletex/sync?${queryParams}`;

  return new EventSource(url, {
    headers: {
      withCredentials: true,
      Authorization: `Bearer ${authToken}`,
    },
  });
}
