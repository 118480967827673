import {
  DocumentPlusIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { TWclassNames } from "../../../utils/Div";
import { PMField } from "../../../utils/PMField";
import { PMSimpleSelectorField } from "../../../utils/PMSimpleSelector/PMSimpleSelectorField";
import ProConfirmation from "../../../utils/ProConfirmation";
import { useEffect } from "react";
import { VariableTypeSelector } from "../../../utils/moduleSelectors/variableTypeSelector";
import { UnitSelector } from "../../../utils/moduleSelectors/unitSelector";
export const ReportVariables = (props) => {
  const { setFieldValue, values, report } = props;

  const orderItems = (rows) => {
    var array = [];
    if (!rows) return;
    rows.map((row, i) => {
      const rowParams = {
        ...row,
        row_order: i,
        draggableId: "id" + row.id,
      };
      array.push(rowParams);
    });
    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: null,
      row_order: null,
      description: "",
      title: "",
    },
  ];

  const [variables, setVariables] = useState(
    orderItems(values.variables.length > 0 ? values.variables : defaultRows)
  );

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedItemRow, setSelectedItemRow] = useState(null);

  const addVariable = () => {
    const newRow = {
      variable_type: "",
      reference: "",
      field1: null,
      field2: null,
      field3: null,
      field4: null,
      unit_id: null,
      note: "",
      // group_row: type == 'group' ? true : false,
      row_order: variables.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + variables.length + 1,
    };

    setVariables((prevState) => [...prevState, newRow]);
  };

  useEffect(() => {
    setFieldValue("variables", variables);
  }, [variables]);

  const removeVariable = (index) => {
    setVariables((prevState) => prevState.filter((_, i) => i !== index));
    setDeleteConfirmationOpen(false);
  };

  const handleRemoveVariable = (index) => {
    setSelectedItemRow(index);
    setDeleteConfirmationOpen(true);
  };

  const handleDuplicateRow = (index) => {
    // Copy everything exept row_order, id and change draggableId
    const newRow = {
      ...variables[index],
      row_order: variables.length,
      id: null,
      draggableId: "id" + variables.length + 1,
    };
    setVariables((prevState) => [...prevState, newRow]);
    console.log("newRow", newRow);
  };

  const updateVariable = (index, name, value) => {
    setVariables((prevState) => {
      const newState = [...prevState];
      newState[index] = { name, value };
      return newState;
    });
  };

  console.log("variables", variables);

  const onItemRowChange = (e, index) => {
    console.log("e", e);
    // e.preventDefault();

    let prevItems = [...variables];
    let item = {
      ...prevItems[index],
      [e.target.name]: e.target.value,
    };

    if (e.target.name === "variable_type_id") {
      item = {
        ...item,
        variable_type: e.variable_type.object,
      };
    }

    if (e.target.name === "unit_id") {
      item = {
        ...item,
        unit: e.item.object,
      };
    }

    prevItems[index] = item;
    setVariables(prevItems);
  };

  // Group by Variable ID
  const groupedData = variables.reduce((acc, curr) => {
    const { variable_type } = curr;
    if (!acc[variable_type?.id]) {
      acc[variable_type?.id] = [];
    }
    acc[variable_type?.id].push(curr);
    return acc;
  }, {});

  return (
    <div className="-mx-4 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table className="w-full">
        <thead className=" bg-blue-50">
          <tr className="">
            <th
              scope="col"
              className="hidden px-6 py-3.5 pr-3 w-60 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Variable
            </th>
            <th
              scope="col"
              className="hidden px-6 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Reference
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Field 1
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Field 2
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 w-[150px] text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Unit
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Note
            </th>
            <th
              scope="col"
              className="hidden relative py-3.5 pl-3 pr-4 sm:pr-6 lg:table-cell"
            >
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="flex-auto sm:flex-none divide-y divide-gray-200 bg-white">
          {variables?.map((variable, index) => {
            return (
              <tr
                key={variable.row_order}
                className="hover:bg-gray-50 flex grid grid-cols-4 gap-4 flex-no wrap sm:table-row"
              >
                <td className="col-span-2 px-3 py-4 text-sm text-gray-500">
                  <VariableTypeSelector
                    name="variable_type_id"
                    // label="Variable Type"
                    selected={variable?.variable_type}
                    onChange={(e) => onItemRowChange(e, index)}
                  />
                </td>

                <td className="col-span-2 px-2 py-2 text-sm text-gray-500 lg:table-cell">
                  <PMField
                    onChange={(e) => onItemRowChange(e, index)}
                    name="reference"
                    placeholder="Reference"
                    value={variable.reference}
                    formik={false}
                  />
                </td>
                <td className="col-span-2 px-2 py-2 text-sm text-gray-500 lg:table-cell">
                  <PMField
                    onChange={(e) => onItemRowChange(e, index)}
                    name="field1"
                    placeholder="Field 1"
                    value={variable.field1}
                    formik={false}
                  />
                </td>
                <td className="col-span-2 px-2 py-2 text-sm text-gray-500 lg:table-cell">
                  <PMField
                    onChange={(e) => onItemRowChange(e, index)}
                    name="field2"
                    placeholder="Field 2"
                    value={variable.field2}
                    formik={false}
                  />
                </td>
                <td className="col-span-1 px-2 py-2 w-20 text-sm text-gray-500 lg:table-cell">
                  <UnitSelector
                    name="unit_id"
                    selected={variable?.unit}
                    onChange={(e) => onItemRowChange(e, index)}
                  />
                </td>
                <td className="col-span-2 px-2 py-2 w-30 text-sm text-gray-500 lg:table-cell">
                  <PMField
                    onChange={(e) => onItemRowChange(e, index)}
                    name="note"
                    placeholder="Note"
                    value={variable.note}
                    formik={false}
                  />
                </td>

                <td className="col-span-1 px-2 py-2 text-sm text-gray-500">
                  <div className="flex gap-4">
                    <div onClick={() => handleRemoveVariable(index)}>
                      <TrashIcon className="cursor-pointer mt-5 h-5 w-5 text-red-500" />
                    </div>

                    <div onClick={() => handleDuplicateRow(index)}>
                      <DocumentPlusIcon className="cursor-pointer mt-5 h-5 w-5 text-gray-500" />
                    </div>
                  </div>
                </td>

                <ProConfirmation
                  severity="confirmation"
                  open={deleteConfirmationOpen}
                  message={"Are you sure you want to delete this row?"}
                  action="delete"
                  module="order_item"
                  // item_id={confirmation.item_id}
                  yesCallback={() => removeVariable(selectedItemRow)}
                  cancelCallback={() => setDeleteConfirmationOpen(false)}
                />
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              colSpan={1}
              className="pl-6 px-6 p-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              <div
                className="flex gap-2 cursor-pointer hover:text-green-500"
                onClick={() => addVariable()}
              >
                <PlusCircleIcon className="w-5 h-5" />
                <span>Ny rad</span>
              </div>
            </th>
          </tr>
          {Object.entries(groupedData).map(([variable_type, items]) => {
            return (
              <tr key={variable_type}>
                <th
                  colSpan={2}
                  className="pl-6 px-6 p-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  {/* Variable name */}
                  {items[0].variable_type
                    ? items[0].variable_type.title
                    : "Missing variable"}
                </th>
                <th className="pl-6 px-6 p-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  {/* Display the sum of actual variable field 2 */}
                  {items.reduce((total, item) => {
                    const value = parseInt(item.field1, 10);
                    return isNaN(value) ? total : total + value;
                  }, 0)}
                </th>
                <th className="pl-6 px-6 p-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  {/* Display the sum of actual variable field 1 */}
                  {items.reduce((total, item) => {
                    const value = parseInt(item.field2, 10);
                    return isNaN(value) ? total : total + value;
                  }, 0)}
                </th>

                <th
                  colSpan={2}
                  className="pl-6 px-6 p-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  {/* Variable name */}
                  {items[0].unit ? items[0].unit.title : "Missing unit"}
                </th>
              </tr>
            );
          })}
        </tfoot>
      </table>
    </div>
  );
};
