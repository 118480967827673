import React, { component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { ResourceError } from "../../../../components/utils/ResourceError";
import {
  ArrowPathRoundedSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import {
  axiosWithToast,
  mapErrors,
  updateUrlWithException,
} from "../../../../components/Utils";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";
import {
  deleteServiceAgreement,
  fetchServiceAgreement,
  reactivateServiceAgreement,
} from "../../../../api/service/agreement";
import { ServiceAgreementInfo } from "../../../../components/dashboard/service/agreements/agreement-info";
import { ReportsStackList } from "../../../../components/dashboard/service/reports/reports-stack-list";
import { ServiceAgreementForm } from "../../../../components/dashboard/service/agreements/agreement-form";
import { Notes } from "../../../../components/dashboard/notes/notes";
import { ServiceAgreementNewReportSection } from "../../../../components/dashboard/service/agreements/new-report-section";
import { ServiceAgreementChangeDateSection } from "../../../../components/dashboard/service/agreements/change-date-section";
import { ServiceAgreementStatus } from "../../../../components/dashboard/service/agreements/utils";
import SimplePrompt from "../../../../components/utils/simplePrompt";
import { ServiceAgreementOrderlines } from "../../../../components/dashboard/service/agreements/agreement-orderlines";
import { OrdersStacklist } from "../../../../components/dashboard/orders/orders-stack-list";

export const ServiceAgreement = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    agreement: {},
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Deconstruct itemState (Store)
  const { isLoading, error, errors, agreement } = itemState;

  const item = agreement;

  const [selectedTab, setSelectedTab] = useState(
    parseInt(searchParams.get("tab")) || 0
  );

  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        tab: selectedTab,
      }
    );
  }, [selectedTab]);

  useEffect(() => {
    getAgreement();
  }, [dispatch]);

  const getAgreement = async () => {
    return fetchServiceAgreement(id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            agreement: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          agreement: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      agreement: data,
    }));
  };

  const [cancelPrompt, setCancelPrompt] = useState(false);

  const cancelAgreement = async () => {
    const payload = {
      id: item?.id,
    };
    axiosWithToast(deleteServiceAgreement(payload), (data) =>
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        agreement: data?.data,
      }))
    );
  };

  const [reactivatePrompt, setReactivatePrompt] = useState(false);

  const reactivateAgreement = async () => {
    const payload = {
      id: item?.id,
    };

    axiosWithToast(reactivateServiceAgreement(payload), (data) =>
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        agreement: data?.data,
      }))
    );
  };

  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const tabs = [
    {
      name: "Detaljer",
      component: <ServiceAgreementInfo item={item} isLoading={isLoading} />,
    },
    {
      name: "Rediger",
      component: <ServiceAgreementForm item={item} />,
    },
    {
      name: "Ordrelinjer",
      component: <ServiceAgreementOrderlines item={item} />,
    },
    {
      name: "Oppdrag",
      component: (
        <ReportsStackList
          module_param={item.id}
          module="agreement"
          filters={[
            {
              name: "agreement",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Ordrer",
      component: (
        <OrdersStacklist
          module_param={item.id}
          module="agreement"
          filters={[
            {
              name: "agreement",
              value: item.id,
            },
          ]}
        />
      ),
    },
  ];

  const headerActions = [
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"service_agreement"} module_param={item?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <HistoriesModalButton
          module={"service_agreement"}
          module_param={item?.id}
        />
      ),
      label: "History",
      description: "",
      path: null,
      action: () => toast.success("Hej Robin"),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      label: "Kanseler",
      description: "",
      path: null,
      action: () => setCancelPrompt(true),
      icon: ExclamationTriangleIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: item?.status === "cancelled",
      menu: true,
    },
    {
      label: "Reaktiver",
      description: "",
      path: null,
      action: () => setReactivatePrompt(true),
      icon: ExclamationTriangleIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: item?.status !== "cancelled",
      menu: true,
    },
  ];

  const headerMeta = [
    {
      component: <ServiceAgreementStatus serviceAgreement={agreement} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  function classNames(...classes) {
    return classes?.filter(Boolean)?.join(" ");
  }

  function agreementTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e?.target?.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs[selectedTab]?.name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab?.name} value={tabIdx}>
                {tab?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={classNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tabIdx === selectedTab ? "bg-blue-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="">
          <ResourceLoading />
        </div>
      );
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    if (!item?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={agreement?.displayTitle}
          meta={headerMeta}
          // mainButton={{
          //   label: "New",
          //   action: () => setCaseDialogOpen(true),
          //   path: null,
          //   color: "",
          //   icon: PlusIcon,
          // }}
        >
          <div className="mx-auto mt-8 grid max-w-7xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
            <div className="space-y-6 lg:col-span-4 lg:col-start-1 sm:col-span-4">
              {agreementTabs()}
              {tabs[selectedTab].component}
            </div>
            <div className="space-y-6 lg:col-span-4 lg:col-start-5 sm:col-span-4">
              <ServiceAgreementNewReportSection item={agreement} />
              <ServiceAgreementChangeDateSection
                item={agreement}
                onRefresh={(data) => handleRefresh(data)}
              />
            </div>
          </div>
        </DashboardHeader>

        <SimplePrompt
          title="Kanseler serviceavtale"
          description="Er du sikker på at du vill kanselere denne serviceavtalen?"
          open={cancelPrompt}
          onClose={() => setCancelPrompt(false)}
          onAccept={() => cancelAgreement()}
        />

        <SimplePrompt
          title="Reaktivere serviceavtale"
          description="Er du sikker på at du vill aktivere denne serviceavtalen igjen?"
          open={reactivatePrompt}
          onClose={() => setReactivatePrompt(false)}
          onAccept={() => reactivateAgreement()}
        />
      </>
    );
  };

  return <>{renderContent()}</>;
};
