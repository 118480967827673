import { Form, Formik } from "formik";
import { PMButton } from "../../utils/PMButton";
import { PMDialog } from "../../utils/PMDialog";
import { PMField } from "../../utils/PMField";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { DocumentViewer } from "./documentViewer";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { dispatchWithToast } from "../../Utils";
import { updateDocumentAsync } from "../../../slices/document";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const EditDocumentDialog = (props) => {
  const { open, onClose, selected, onRefresh, error, errors } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [document, setDocument] = useState(props.document);

  return (
    <PMDialog open={open} onClose={onClose} title="Edit document">
      <Formik
        initialValues={{
          id: document?.id,
          title: document?.title,
          filename: document?.file?.filename,
          submit: null,
        }}
        validationSchema={Yup.object({})}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(updateDocumentAsync(values)),
              (data) => {
                if (onRefresh) {
                  onRefresh(data);
                }
                setDocument(data);
                setLoading(false);
                onClose();
              },
              () => setLoading(false)
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            setLoading(false);
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => {
          if (loading) {
            return <ResourceLoading />;
          }

          return (
            <Form className="mb-20">
              <div className="py-2">
                <PMField label="Title" name="title" {...formik} />
              </div>
              <div className="py-2">
                <PMField
                  disabled
                  label="Filename"
                  name="filename"
                  {...formik}
                />
              </div>

              <PMButton
                type="submit"
                styleType="primary"
                className="bg-green-400 hover:bg-green-200"
              >
                Save
              </PMButton>
            </Form>
          );
        }}
      </Formik>
    </PMDialog>
  );
};
